import { Flex, Text, Icon, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import { lives } from "../data/content";
import { VideoModal } from "./VideoModal";

export function VideoLauncher() {
  const [id, setID] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex flexDir="column" gap="1rem" w="80vw">
      {lives.map(({ icon, label, link }) => (
        <Flex
          _hover={{ borderColor: "white" }}
          alignContent="center"
          border="5px solid #ffcc00"
          bg={id === link ? "golden" : "transparent"}
          borderRadius="md"
          cursor="pointer"
          flexDir="column"
          justifyContent="center"
          key={label}
          p="1rem"
          onClick={() => {
            setID(link);
            onOpen();
          }}
          role="button"
        >
          <Icon
            as={icon}
            boxSize="64px"
            color={id === link ? "black" : "golden"}
            m="auto"
          />
          <Text
            as="span"
            color={id === link ? "black" : "golden"}
            fontWeight={900}
            textAlign="center"
          >
            {label}
          </Text>
        </Flex>
      ))}

      <VideoModal id={id} isOpen={isOpen} onClose={onClose} />
    </Flex>
  );
}
