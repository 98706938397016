import React from "react";
import {
  Box,
  Flex,
  Heading,
  Icon,
  Link,
  LinkBox,
  LinkOverlay,
  SimpleGrid,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { Hero } from "../components/Hero";
import Layout from "../components/Layout";
import { NewsletterForm } from "../components/NewsletterForm";
import { Section } from "../components/Section";
import { VideoLauncher } from "../components/VideoLauncher";
import { VideoSelector } from "../components/VideoSelector";
import { cvvLinks } from "../data/content";

export default function IndexPage() {
  const [isMobile] = useMediaQuery("(max-width: 1080px)");

  return (
    <Layout>
      <Hero />
      <Section
        bg="black"
        bgImg="images/vida-back.svg"
        bgRepeat="no-repeat"
        bgSize="contain"
        bgPos="bottom right"
        waveColor="white"
      >
        <Box m="10rem 0">
          <Text
            color="golden"
            fontSize={["1rem", "1.3rem"]}
            textAlign="center"
            sx={{ strong: { fontWeight: "900" } }}
          >
            <strong>Setembro Amarelo</strong> é uma campanha para
            conscientização e prevenção ao suicídio. Segundo a OMS (2019), há{" "}
            <strong>700 mil casos</strong> de suícidios anualmente em todo o
            mundo, enquanto no Brasil há em torno de 14 mil casos. <br />
            <br />
            Quase todos os casos são em decorrência de alguma doença mental que
            o indivíduo possuia e não foi cuidada adequadamente, assim muitos
            casos podem ser impedidos. É nesse sentido que a{" "}
            <strong>Fepo - Psicólogos Online</strong> disponibiliza abaixo todo
            o conteudo das nossas lives, além de materiais escritos e os
            Psicólogos que atendem pela plataforma. Espero que você e as pessoas
            que você estima fiquem bem.
          </Text>
          <Link
            color="white"
            display="block"
            fontSize="1.2rem"
            fontWeight="900"
            href="https://www.setembroamarelo.com"
            isExternal
            m={["4rem auto", "auto"]}
            textAlign="center"
            w={["80vw", "30rem"]}
          >
            Leia mais sobre Setembro Amarelo Atividades de Prevenção
          </Link>
        </Box>
      </Section>

      <Section
        bg="white"
        bgImg="images/heart-back.svg"
        bgPos="center"
        bgRepeat="no-repeat"
        bgSize="contain"
        color="black"
        h="400px"
        title="Gostaria de conversar com um psicólogo online?"
        waveColor="black"
        sx={{
          h2: { fontSize: ["2rem", "3.2rem"], width: "40rem", lineHeight: 1 },
        }}
      >
        <Link
          _hover={{ color: "white", textDecoration: "none" }}
          bg="black"
          borderRadius="2rem"
          color="golden"
          fontSize={["1rem", "1.2rem"]}
          fontWeight="900"
          href="https://www.fepo.com.br/psicologo/"
          p="1rem 2rem"
          textAlign="center"
        >
          agendar minha sessão
        </Link>
      </Section>

      <Section
        bg="black"
        bgImg="images/lives-back.svg"
        bgPos="bottom left"
        bgRepeat="no-repeat"
        bgSize="contain"
        color="golden"
        container={false}
        title="Lives"
        waveColor="#ffcc00"
      >
        {isMobile ? <VideoLauncher /> : <VideoSelector />}
      </Section>

      <Section bg="golden" waveColor="#fff8db">
        <Heading
          color="black"
          fontFamily="Montserrat"
          fontSize="1.5rem"
          fontWeight={900}
        >
          Fique por dentro das novidades da Fepo!
        </Heading>
        <NewsletterForm />
      </Section>

      <Section
        bg="golden"
        bgImg="images/beige-back.svg"
        bgPos="bottom"
        bgSize="cover"
        waveColor="#fff8db"
        width="80vw"
      >
        <Heading color="black" fontSize="4.5rem">
          CVV
        </Heading>

        <Text fontWeight="900" my="2rem">
          O CVV – Centro de Valorização da Vida realiza apoio emocional e
          prevenção do suicídio, atendendo voluntária e gratuitamente todas as
          pessoas que querem e precisam conversar, sob total sigilo por
          telefone, email e chat 24 horas todos os dias.
        </Text>

        <SimpleGrid columns={[1, 4]} gap="1rem" mb="4rem">
          {cvvLinks.map(({ icon, label, link }) => (
            <LinkBox key="link">
              <Flex
                bg="lightYellow"
                _hover={{ bg: "white" }}
                borderRadius="md"
                key={link}
                flexDir="column"
                alignItems="center"
                justifyContent="center"
                border="5px solid #ffcc00"
                transition="background 0.2s"
                p="1rem"
                gap="0.5rem"
              >
                <Icon as={icon} boxSize="64px" />
                <LinkOverlay
                  fontFamily="body"
                  fontSize="sm"
                  fontWeight={900}
                  href={`https://www.cvv.org.br/${link}/`}
                  isExternal
                  lineHeight={1}
                  textAlign="center"
                >
                  {label}
                </LinkOverlay>
              </Flex>
            </LinkBox>
          ))}
        </SimpleGrid>
      </Section>
    </Layout>
  );
}
