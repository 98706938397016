import React from "react";
import {
  AspectRatio,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
} from "@chakra-ui/react";
import ReactPlayer from "react-player/youtube";

type Props = {
  id: string;
  isOpen: boolean;
  onClose: () => void;
};

export function VideoModal({ id, isOpen, onClose }: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalContent borderRadius={0} bg="black">
        <ModalCloseButton color="white" size="xl" />
        <ModalBody display="grid" placeItems="center">
          <AspectRatio
            borderRadius="md"
            overflow="hidden"
            w="90vw"
            ratio={16 / 9}
          >
            <ReactPlayer
              height="100%"
              url={`https://www.youtube.com/watch?v=${id}`}
              width="100%"
            />
          </AspectRatio>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
