import React from "react";
import { AspectRatio, Box, Button, Center, Icon, Text } from "@chakra-ui/react";
import ReactPlayer from "react-player/youtube";

import type { IconType } from "react-icons";
import type { Dispatch, SetStateAction } from "react";

type VideoInfo = {
  link: string;
  label: string;
  icon: IconType;
};

type Props = {
  id: string;
  video?: VideoInfo;
  playing: boolean;
  setPlaying: Dispatch<SetStateAction<boolean>>;
};

export function VideoPlayer({ id, video, playing, setPlaying }: Props) {
  return (
    <AspectRatio w="80vw" ratio={16 / 9}>
      <Center h="100%" w="100%" borderRadius="xl">
        <Center
          bg="golden"
          bgImg="images/amarelo-back.svg"
          bgSize="cover"
          bottom={0}
          display={playing === true ? "none" : "flex"}
          left={0}
          pos="absolute"
          right={0}
          top={0}
        >
          <Box width="80%" textAlign="center">
            <Icon as={video?.icon} boxSize="30%" m="auto" color="black" />
            <Text
              color="black"
              fontSize="2.5rem"
              fontWeight={900}
              lineHeight={1}
              m="auto"
              textAlign="center"
              w="60%"
            >
              {video?.label}
            </Text>
            <Button
              _hover={{ bg: "gray.900" }}
              bg="black"
              borderRadius="2rem"
              color="white"
              m="5rem auto 0"
              onClick={() => setPlaying(true)}
              p="1rem"
              textTransform="uppercase"
              w="10rem"
            >
              Play
            </Button>
          </Box>
        </Center>

        <ReactPlayer
          height="100%"
          onPause={() => setPlaying(false)}
          playing={playing}
          url={`https://www.youtube.com/watch?v=${id}`}
          width="100%"
        />
      </Center>
    </AspectRatio>
  );
}
