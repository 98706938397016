import React, { useState } from "react";
import {
  AspectRatio,
  Flex,
  Icon,
  IconButton,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { lives } from "../data/content";
import { VideoPlayer } from "./VideoPlayer";

export function VideoSelector() {
  const [currentPage, setCurrentPage] = useState(1);
  const [id, setID] = useState("F7RcRobWeEE");
  const [playing, setPlaying] = useState(false);
  const itemsPerPage = 3;
  const currentVideo = lives.find((item) => item.link === id);
  const pagedItems = lives.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <Flex
      alignContent="stretch"
      flexDir="column"
      gap="1rem"
      maxW="80vw"
      mb="4rem"
    >
      <VideoPlayer
        id={id}
        playing={playing}
        setPlaying={setPlaying}
        video={currentVideo}
      />

      <Flex justifyContent="space-between" alignContent="center" gap="1rem">
        <IconButton
          _hover={{ bg: "transparent", color: "white" }}
          aria-label="Anterior"
          bg="transparent"
          color="golden"
          disabled={currentPage === 1}
          fontSize="5rem"
          icon={<BiChevronLeft />}
          my="auto"
          onClick={() => setCurrentPage(currentPage - 1)}
        />

        <SimpleGrid columns={[1, 3]} w="80vw" gap="1rem">
          {pagedItems.map(({ icon, label, link }) => (
            <AspectRatio key={link} ratio={16 / 9}>
              <Flex
                _hover={{ borderColor: "white" }}
                alignContent="center"
                border="5px solid #ffcc00"
                bg={id === link ? "golden" : "transparent"}
                borderRadius="md"
                cursor="pointer"
                flexDir="column"
                justifyContent="center"
                p="1rem"
                onClick={() => {
                  setPlaying(true);
                  setID(link);
                }}
                role="button"
              >
                <Icon
                  as={icon}
                  boxSize="64px"
                  color={id === link ? "black" : "golden"}
                  m="auto"
                />
                <Text
                  as="span"
                  color={id === link ? "black" : "golden"}
                  fontWeight={900}
                  textAlign="center"
                >
                  {label}
                </Text>
              </Flex>
            </AspectRatio>
          ))}
        </SimpleGrid>

        <IconButton
          _hover={{ bg: "transparent", color: "white" }}
          aria-label="Próximo"
          bg="transparent"
          color="golden"
          disabled={currentPage === 2}
          fontSize="5rem"
          icon={<BiChevronRight />}
          my="auto"
          onClick={() => setCurrentPage(currentPage + 1)}
        />
      </Flex>
    </Flex>
  );
}
