import { Box, Flex, Heading } from "@chakra-ui/react";
import React from "react";
import { Wave } from "./Wave";

export function Hero() {
  return (
    <Flex
      alignItems="center"
      as="section"
      background="#ffcc00 url(images/amarelo-hero.svg) no-repeat bottom center"
      backgroundSize="cover"
      color="white"
      h="100vh"
      justifyContent="center"
      minH="600px"
      pos="relative"
    >
      <Heading
        as="h1"
        fontSize={["20vw", "12vw"]}
        fontWeight="800"
        color="black"
        lineHeight={0.7}
        textAlign="center"
      >
        setembro
        <br /> amarelo
      </Heading>
      <Box pos="absolute" bottom={0} left={0} right={0}>
        <Wave color="black" />
      </Box>
    </Flex>
  );
}
