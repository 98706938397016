import React from "react";
import type { ReactNode } from "react";
import {
  Box,
  Container,
  Flex,
  FlexProps,
  Heading,
  Text,
} from "@chakra-ui/react";
import { Wave } from "./Wave";

type Props = FlexProps & {
  bg?: string;
  children: ReactNode;
  color2?: string;
  color?: string;
  container?: boolean;
  subtitle?: string;
  title?: string;
  waveColor: string;
  width?: string;
};

export function Section({
  bg,
  children,
  color,
  color2,
  container = true,
  subtitle,
  title,
  waveColor,
  width,
  ...rest
}: Props) {
  return (
    <Flex bg={bg} pos="relative" flexDir="column" {...rest}>
      {title && (
        <Heading
          as="h2"
          color={color}
          fontSize={["10vw", "7vw"]}
          maxW="80vw"
          m={["4rem auto 2rem", "auto"]}
        >
          {title}
        </Heading>
      )}

      {subtitle && (
        <Container>
          <Text
            color={color2}
            fontSize="1.4rem"
            fontWeight="black"
            maxW="80vw"
            lineHeight={1}
            m="auto"
            textAlign="center"
          >
            {subtitle}
          </Text>
        </Container>
      )}

      <Box
        maxW={container ? ["80vw", "60vw"] : "auto"}
        m="auto"
        mb="10rem"
        w={width}
      >
        {children}
      </Box>
      <Wave color={waveColor} />
    </Flex>
  );
}
