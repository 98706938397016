import React from "react";
import { Button, Flex, Input } from "@chakra-ui/react";

export function NewsletterForm() {
  return (
    <>
      <Flex mt="1rem" mb="4rem" flexDir={["column", "row"]} gap={["1rem", 0]}>
        <Input
          _hover={{ bg: ["transparent", "lightYellow"] }}
          _placeholder={{ color: ["black", "orange"], textAlign: "center" }}
          bg={["transparent", "white"]}
          border={0}
          borderBottom={["2px dashed #000000", 0]}
          borderRadius={0}
          borderLeftRadius={[0, "5rem"]}
          color={["black", "brown"]}
          fontWeight="bold"
          placeholder="Seu nome"
          textAlign="center"
        />
        <Input
          _hover={{ bg: ["transparent", "lightYellow"] }}
          _placeholder={{ color: ["black", "orange"], textAlign: "center" }}
          bg={["transparent", "white"]}
          border={0}
          borderRadius={0}
          color={["black", "brown"]}
          fontWeight="bold"
          p={5}
          placeholder="seu@email.com.br"
          textAlign="center"
        />
        <Button
          _hover={{ bg: "brown" }}
          bg="black"
          borderRadius={["5rem", 0]}
          borderRightRadius={["5rem", "5rem"]}
          color="white"
          fontWeight="bold"
          p={5}
          w={["auto", "10rem"]}
        >
          Enviar
        </Button>
      </Flex>
    </>
  );
}
