import {
  BiChat,
  BiHomeHeart,
  BiMailSend,
  BiPhoneCall,
  BiBlock,
  BiBookReader,
  BiBrain,
  BiConfused,
  BiDroplet,
  BiMeteor,
  BiTired,
} from "react-icons/bi";

export const cvvLinks = [
  { icon: BiChat, link: "chat", label: "Chat" },
  { icon: BiPhoneCall, link: "ligue-188", label: "Disque 188" },
  { icon: BiMailSend, link: "e-mail", label: "E-mail" },
  { icon: BiHomeHeart, link: "postos-de-atendimento", label: "Atendimento" },
];

export const lives = [
  { icon: BiDroplet, label: "Depressão", link: "F7RcRobWeEE" },
  { icon: BiMeteor, label: "Trauma Emocional", link: "-2e0v8pVNzE" },
  {
    icon: BiConfused,
    label: "Como a ansiedade afeta as emoções?",
    link: "pulmcQc-dJk",
  },
  { icon: BiBookReader, label: "Autoconhecimento", link: "JqGMlSwmg2c" },
  { icon: BiBlock, label: "Vazio Emocional", link: "J4A5Zcq3JFA" },
  {
    icon: BiTired,
    label: "Dificuldade Em Se Relacionar",
    link: "IvoVPNtbxwM",
    //link: "IvoVPNtbxwM&t=1331s",
  },
  { icon: BiBrain, label: "Estresse", link: "_S7KC31c7Sc" },
];
